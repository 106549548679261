import {MaterialSource, MaterialType} from '@hconnect/common/types'
import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {MaterialSettingsTabs} from '../../../../../../src/modules/common/enums'
import {HeaderTab} from '../../../../common/components/HeaderTabs'
// FIXME:in HCP-82136 sync local setting types with common types
import {MaterialWithRecipes} from '../../../types'

interface AvailableHeaderTabsParams {
  materialWithRecipes: MaterialWithRecipes | undefined
  isLoading: boolean
}

export const useAvailableHeaderTabs = ({
  materialWithRecipes,
  isLoading
}: AvailableHeaderTabsParams): HeaderTab[] => {
  const {t} = useTranslation()

  const isNewMaterial = !materialWithRecipes
  const isProducedOrBoughtAndProduced =
    !!materialWithRecipes &&
    (materialWithRecipes.source === MaterialSource.ProducedInPlant ||
      materialWithRecipes.source === MaterialSource.BoughtAndProduced)

  const shouldDisableTabs = isLoading || isNewMaterial

  return useMemo(() => {
    const producedInPlantTabs: HeaderTab[] = [
      {
        value: MaterialSettingsTabs.Recipes,
        label: t(`materialsSettings.${MaterialSettingsTabs.Recipes}`, {
          amount: materialWithRecipes?.recipes.length ?? 0
        }),
        disabled: shouldDisableTabs
      },
      {
        value: MaterialSettingsTabs.PxTrendTags,
        label: t(`materialsSettings.${MaterialSettingsTabs.PxTrendTags}`, {
          amount: materialWithRecipes?.pxTrendCounters?.length ?? 0
        }),
        disabled: shouldDisableTabs
      }
    ]

    return [
      {
        value: MaterialSettingsTabs.General,
        label: t(`materialsSettings.${MaterialSettingsTabs.General}`),
        disabled: false
      },
      ...(process.env.REACT_APP_STAGE === 'qa' &&
      materialWithRecipes?.type !== MaterialType.NonReportedMaterials
        ? [
            {
              value: MaterialSettingsTabs.Classification,
              label: t(`materialsSettings.${MaterialSettingsTabs.Classification}`),
              disabled: shouldDisableTabs
            }
          ]
        : []),
      {
        value: MaterialSettingsTabs.SapCodes,
        label: t(`materialsSettings.${MaterialSettingsTabs.SapCodes}`, {
          amount: materialWithRecipes?.products.length ?? 0
        }),
        disabled: shouldDisableTabs
      },
      {
        value: MaterialSettingsTabs.LimsCodes,
        label: t(`materialsSettings.${MaterialSettingsTabs.LimsCodes}`, {
          amount: materialWithRecipes?.limsMaterials.length ?? 0
        }),
        disabled: shouldDisableTabs
      },
      ...(isProducedOrBoughtAndProduced ? producedInPlantTabs : [])
    ]
  }, [isProducedOrBoughtAndProduced, materialWithRecipes, t, shouldDisableTabs])
}
