import {MaterialType} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit/src/common/utils/testingUtils'
import {Stack, Skeleton} from '@mui/material'

import {ClassificationAutocomplete} from './ClassificationAutocomplete'
import {ClassificationCheckBox} from './ClassificationCheckbox'

import {ClinkerClassificationInputNames} from '@settings/modules/common/enums'
import {useMaterialsClassificationMetadata} from '@settings/modules/materials/hooks'

interface ClinkerClassificationProps {
  isDisabled: boolean
}

export const ClinkerClassification: React.FC<ClinkerClassificationProps> = ({isDisabled}) => {
  const {data: metadata} = useMaterialsClassificationMetadata(MaterialType.Clinker)

  if (!metadata) {
    return <Skeleton variant="rectangular" sx={{height: ({spacing}) => spacing(43)}} />
  }

  return (
    <Stack
      {...dataTestId('clinker_classification_form')}
      spacing={2}
      sx={({spacing}) => ({maxWidth: spacing(49), height: spacing(43)})}
    >
      <ClassificationAutocomplete
        materialType={MaterialType.Clinker}
        inputName={ClinkerClassificationInputNames.Type}
        metadata={metadata}
        isDisabled={isDisabled}
      />
      <ClassificationAutocomplete
        materialType={MaterialType.Clinker}
        inputName={ClinkerClassificationInputNames.Grade}
        metadata={metadata}
        isDisabled={isDisabled}
      />
      <ClassificationCheckBox
        materialType={MaterialType.Clinker}
        inputName={ClinkerClassificationInputNames.SulphateResistance}
        metadata={metadata}
        customValues={{trueValue: 'SR', falseValue: '-'}}
        isDisabled={isDisabled}
      />
      <ClassificationCheckBox
        materialType={MaterialType.Clinker}
        inputName={ClinkerClassificationInputNames.AlkaliContent}
        metadata={metadata}
        customValues={{trueValue: 'LA', falseValue: '-'}}
        isDisabled={isDisabled}
      />
      <ClassificationCheckBox
        materialType={MaterialType.Clinker}
        inputName={ClinkerClassificationInputNames.LowHeat}
        metadata={metadata}
        customValues={{trueValue: 'LH', falseValue: '-'}}
        isDisabled={isDisabled}
      />
    </Stack>
  )
}
