import {MaterialType} from '@hconnect/common/types'
import {FormControl, FormHelperText} from '@mui/material'
import {Controller, useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {SegmentedButton} from '../../../../common/components/form/SegmentedButton'
import {requiredValidator} from '../../../../common/utils'
import {
  ClassificationInputName,
  ClassificationMetadataEntry,
  ClassificationMetadataValueSet
} from '../../../types'
import {MaterialFormDefaultValues} from '../../MaterialDetailsCard/hooks'

interface ClassificationAutocompleteProps<T extends MaterialType> {
  materialType: T
  inputName: ClassificationInputName<T>
  metadata: ClassificationMetadataEntry<T>
  isDisabled: boolean
  onChangeCb?: () => void
}

export const ClassificationSegmentedButton = <T extends MaterialType>({
  materialType,
  inputName,
  metadata,
  onChangeCb,
  isDisabled
}: ClassificationAutocompleteProps<T>) => {
  const {t} = useTranslation()

  const {control, watch} = useFormContext<MaterialFormDefaultValues>()

  /// TODO: This is a copy-paste from ClassificationCheckBox.tsx. It should be extracted to a separate function
  const validValueSet = metadata.inputs[inputName].valueSets.find(
    (valueSet: ClassificationMetadataValueSet) => {
      // Agreement with BE: for now if valuesShownWnen is undefined, it's the only valid value set
      if (valueSet.valuesShownWhen === undefined) {
        return true
      }
      // Agreement with BE: for now any field can be dependant only on one other field
      const conditionForValueSet = valueSet.valuesShownWhen[0]

      const conditionCurrentFormValue = watch(
        `classification.${conditionForValueSet.inputName}`
      ) as string | null
      return conditionCurrentFormValue === conditionForValueSet.value
    }
  )
  const options = validValueSet?.values || []

  return (
    <Controller
      control={control}
      rules={requiredValidator(t)}
      name={`classification.${inputName}`}
      render={({field: {value, onChange}, fieldState: {error}}) => (
        <FormControl>
          <SegmentedButton
            value={value + ''}
            onChange={(e) => {
              {
                onChange(e)
                onChangeCb?.()
              }
            }}
            isDisabled={isDisabled}
            options={options.map((option) => ({
              id: `materials-classification-type-${option}-button`,
              value: option,
              title: option,
              dataTestId: `${materialType}_classification_${inputName}_${option}`
            }))}
          />
          {error?.message && <FormHelperText error>{error.message}</FormHelperText>}
        </FormControl>
      )}
    />
  )
}
