import {MaterialType} from '@hconnect/common/types'

import {
  CementClassificationInputNames,
  ClinkerClassificationInputNames,
  RawMaterialClassificationInputNames,
  FuelClassificationInputNames,
  EnhancersForCementClassificationInputNames,
  AdditivesForClinkerClassificationInputNames,
  NonReportedMaterialsClassificationInputNames
} from '../../common/enums'

export type ClassificationDTO = {materialType: MaterialType} & Partial<{
  [key in ClassificationInputName<MaterialType>]: string
}>

export type ClassificationInputName<T extends MaterialType = MaterialType> =
  T extends MaterialType.Cement
    ? CementClassificationInputNames
    : T extends MaterialType.Clinker
      ? ClinkerClassificationInputNames
      : T extends MaterialType.RawMaterials
        ? RawMaterialClassificationInputNames
        : T extends MaterialType.Fuels
          ? FuelClassificationInputNames
          : T extends MaterialType.EnhancersForCement
            ? EnhancersForCementClassificationInputNames
            : T extends MaterialType.AdditivesForClinker
              ? AdditivesForClinkerClassificationInputNames
              : T extends MaterialType.NonReportedMaterials
                ? NonReportedMaterialsClassificationInputNames
                : never

export type ClassificationMetadataValuesShownWhen<T extends MaterialType> = {
  inputName: ClassificationInputName<T>
  value: string
}

export type ClassificationMetadataValueSet<T extends MaterialType = MaterialType> = {
  valuesShownWhen?: ClassificationMetadataValuesShownWhen<T>[]
  values: string[]
}

export type ClassificationMetadataValueSets<T extends MaterialType> = {
  valueSets: ClassificationMetadataValueSet<T>[]
}

export type ClassificationMetadataInputs<T extends MaterialType> = Record<
  ClassificationInputName<T>,
  ClassificationMetadataValueSets<T>
>

export interface ClassificationMetadataEntry<T extends MaterialType = MaterialType> {
  materialType: T
  inputs: ClassificationMetadataInputs<T>
}

export type CementClassificationMetadata = ClassificationMetadataEntry<MaterialType.Cement>
export type ClinkerClassificationMetadata = ClassificationMetadataEntry<MaterialType.Clinker>
export type RawMaterialsClassificationMetadata =
  ClassificationMetadataEntry<MaterialType.RawMaterials>
export type FuelsClassificationMetadata = ClassificationMetadataEntry<MaterialType.Fuels>
export type EnhancersForCementClassificationMetadata =
  ClassificationMetadataEntry<MaterialType.EnhancersForCement>
export type AdditivesForClinkerClassificationMetadata =
  ClassificationMetadataEntry<MaterialType.AdditivesForClinker>

export type ClassificationMetadataResponse = {
  metadata: ClassificationMetadataEntry[]
}
