import {MaterialType} from '@hconnect/common/types'
import {Grid, Skeleton} from '@mui/material'
import {useFormContext} from 'react-hook-form'

import {CementClassificationInputNames} from '../../../../../modules/common/enums'
import {useMaterialsClassificationMetadata} from '../../../hooks/queries'
import {MaterialFormDefaultValues} from '../../MaterialDetailsCard/hooks'
import {getEmptyClassification} from '../../MaterialDetailsCard/hooks/useMaterialFormDefaultValues'

import {ClassificationAutocomplete} from './ClassificationAutocomplete'
import {ClassificationCheckBox} from './ClassificationCheckbox'

interface CementClassificationProps {
  isDisabled: boolean
}

export const CementClassification: React.FC<CementClassificationProps> = ({isDisabled}) => {
  const {data: cementClassificationMetadata} = useMaterialsClassificationMetadata(
    MaterialType.Cement
  )

  const {setValue, getValues, clearErrors} = useFormContext<MaterialFormDefaultValues>()

  return cementClassificationMetadata ? (
    <Grid container columns={5} rowSpacing={3} columnSpacing={1}>
      <Grid item xs={5} md={1.5}>
        <ClassificationAutocomplete
          materialType={MaterialType.Cement}
          inputName={CementClassificationInputNames.Standard}
          metadata={cementClassificationMetadata}
          isDisabled={isDisabled}
          onChangeCb={() => {
            setValue('classification', {
              ...getEmptyClassification(MaterialType.Cement),
              [CementClassificationInputNames.Standard]: getValues(
                `classification.${CementClassificationInputNames.Standard}`
              )
            })
            clearErrors('classification')
          }}
        />
      </Grid>
      <Grid item xs={5} md={1}>
        <ClassificationAutocomplete
          materialType={MaterialType.Cement}
          inputName={CementClassificationInputNames.Type}
          metadata={cementClassificationMetadata}
          isDisabled={isDisabled}
          onChangeCb={() => {
            setValue('classification', {
              ...getValues('classification'),
              [CementClassificationInputNames.Const1]: null,
              [CementClassificationInputNames.Const2]: null
            })
            clearErrors([
              `classification.${CementClassificationInputNames.Const1}`,
              `classification.${CementClassificationInputNames.Const2}`
            ])
          }}
        />
      </Grid>
      <Grid item xs={5} md={0.8}>
        <ClassificationAutocomplete
          materialType={MaterialType.Cement}
          inputName={CementClassificationInputNames.Const1}
          metadata={cementClassificationMetadata}
          isDisabled={isDisabled}
        />
      </Grid>
      <Grid item xs={5} md={0.8}>
        <ClassificationAutocomplete
          materialType={MaterialType.Cement}
          inputName={CementClassificationInputNames.Const2}
          metadata={cementClassificationMetadata}
          isDisabled={isDisabled}
        />
      </Grid>
      <Grid item xs={5} md={0.8}>
        <ClassificationAutocomplete
          materialType={MaterialType.Cement}
          inputName={CementClassificationInputNames.Strength1}
          metadata={cementClassificationMetadata}
          isDisabled={isDisabled}
        />
      </Grid>
      <Grid item xs={5} md={0.8}>
        <ClassificationAutocomplete
          materialType={MaterialType.Cement}
          inputName={CementClassificationInputNames.Strength2}
          metadata={cementClassificationMetadata}
          isDisabled={isDisabled}
        />
      </Grid>
      <Grid item xs={5} md={1}>
        <ClassificationAutocomplete
          materialType={MaterialType.Cement}
          inputName={CementClassificationInputNames.SulphateResistance}
          metadata={cementClassificationMetadata}
          isDisabled={isDisabled}
        />
      </Grid>
      <Grid item xs={5} md={0.8}>
        <ClassificationAutocomplete
          materialType={MaterialType.Cement}
          inputName={CementClassificationInputNames.SulphurLevel}
          metadata={cementClassificationMetadata}
          isDisabled={isDisabled}
        />
      </Grid>
      <Grid item xs={5} md={1}>
        <ClassificationAutocomplete
          materialType={MaterialType.Cement}
          inputName={CementClassificationInputNames.HeatOfHydration}
          metadata={cementClassificationMetadata}
          isDisabled={isDisabled}
        />
      </Grid>
      <Grid item xs={5} md={0.8}>
        <ClassificationAutocomplete
          materialType={MaterialType.Cement}
          inputName={CementClassificationInputNames.LowAlkali}
          metadata={cementClassificationMetadata}
          isDisabled={isDisabled}
        />
      </Grid>
      <Grid item xs={5} md={1.5}>
        <ClassificationAutocomplete
          materialType={MaterialType.Cement}
          inputName={CementClassificationInputNames.SpecialCharacteristics}
          metadata={cementClassificationMetadata}
          isDisabled={isDisabled}
        />
      </Grid>
      <Grid item xs={5} md={5}>
        <ClassificationCheckBox
          materialType={MaterialType.Cement}
          inputName={CementClassificationInputNames.IntermediateMillProduct}
          metadata={cementClassificationMetadata}
          customValues={{trueValue: 'Yes', falseValue: 'No'}}
          isDisabled={isDisabled}
        />
      </Grid>
      <Grid item xs={2.6}>
        <ClassificationAutocomplete
          materialType={MaterialType.Cement}
          inputName={CementClassificationInputNames.CategoryForAnnualReport}
          metadata={cementClassificationMetadata}
          isDisabled={isDisabled}
        />
      </Grid>
      <Grid item xs={2.6}>
        <ClassificationAutocomplete
          materialType={MaterialType.Cement}
          inputName={CementClassificationInputNames.MethodOfFinenessDetermination}
          metadata={cementClassificationMetadata}
          isDisabled={isDisabled}
        />
      </Grid>
      <Grid item xs={2.6}>
        <ClassificationAutocomplete
          materialType={MaterialType.Cement}
          inputName={CementClassificationInputNames.ReferenceMeshSizeForResidue}
          metadata={cementClassificationMetadata}
          isDisabled={isDisabled}
        />
      </Grid>
    </Grid>
  ) : (
    <Skeleton variant="rectangular" sx={{height: ({spacing}) => spacing(66)}} />
  )
}
