import {MaterialType} from '@hconnect/common/types'
import {Skeleton, Stack} from '@mui/material'
import {useFormContext} from 'react-hook-form'

import {EnhancersForCementClassificationInputNames} from '../../../../../modules/common/enums'
import {useMaterialsClassificationMetadata} from '../../../hooks/queries'
import {MaterialFormDefaultValues} from '../../MaterialDetailsCard/hooks'
import {getEmptyClassification} from '../../MaterialDetailsCard/hooks/useMaterialFormDefaultValues'

import {ClassificationAutocomplete} from './ClassificationAutocomplete'
import {ClassificationTextField} from './ClassificationTextField'

interface EnhancersForCementClassificationProps {
  isDisabled: boolean
}

export const EnhancersForCementClassification: React.FC<EnhancersForCementClassificationProps> = ({
  isDisabled
}) => {
  const {data: enhancersForCementClassificationMetadata} = useMaterialsClassificationMetadata(
    MaterialType.EnhancersForCement
  )

  const {setValue, getValues, clearErrors} = useFormContext<MaterialFormDefaultValues>()

  return enhancersForCementClassificationMetadata ? (
    <Stack direction="column" sx={{maxWidth: ({spacing}) => spacing(49)}} spacing={2.3}>
      <ClassificationAutocomplete
        materialType={MaterialType.EnhancersForCement}
        inputName={EnhancersForCementClassificationInputNames.Category}
        metadata={enhancersForCementClassificationMetadata}
        isDisabled={isDisabled}
        onChangeCb={() => {
          const resetClassification = {
            ...getEmptyClassification(MaterialType.EnhancersForCement),
            [EnhancersForCementClassificationInputNames.Category]: getValues(
              `classification.${EnhancersForCementClassificationInputNames.Category}`
            )
          }
          setValue('classification', resetClassification)
          clearErrors('classification')
        }}
      />
      <ClassificationAutocomplete
        materialType={MaterialType.EnhancersForCement}
        inputName={EnhancersForCementClassificationInputNames.SubCategory}
        metadata={enhancersForCementClassificationMetadata}
        isDisabled={isDisabled}
      />
      <ClassificationAutocomplete
        materialType={MaterialType.EnhancersForCement}
        inputName={EnhancersForCementClassificationInputNames.Brand}
        metadata={enhancersForCementClassificationMetadata}
        isDisabled={isDisabled}
        onChangeCb={() => {
          setValue('classification', {
            ...getValues('classification'),
            [EnhancersForCementClassificationInputNames.OtherBrand]: null
          })
          clearErrors(`classification.${EnhancersForCementClassificationInputNames.OtherBrand}`)
        }}
      />
      <ClassificationTextField
        materialType={MaterialType.EnhancersForCement}
        inputName={EnhancersForCementClassificationInputNames.OtherBrand}
        metadata={enhancersForCementClassificationMetadata}
        isDisabled={isDisabled}
      />
    </Stack>
  ) : (
    <Skeleton variant="rectangular" sx={{height: ({spacing}) => spacing(40)}} />
  )
}
