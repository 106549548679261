import {MaterialType} from '@hconnect/common/types'
import {dataTestId, useTranslation} from '@hconnect/uikit'
import {InfoOutlined} from '@mui/icons-material'
import {Stack, Tooltip, Skeleton, Typography} from '@mui/material'

import {
  CommonMaterialClassificationInputNames,
  RawMaterialClassificationInputNamesInternal
} from '../../../../..//modules/common/enums'
import {useMaterialsClassificationMetadata} from '../../../hooks'
import {MaterialWithRecipes} from '../../../types'
import {useMaterialForm} from '../hooks/useMaterialForm'

import {ClassificationAutocomplete} from './ClassificationAutocomplete'
import {ClassificationCheckBox} from './ClassificationCheckbox'
import {ClassificationSegmentedButton} from './ClassificationSegmentedButton'

interface RawMaterialsClassificationProps {
  material: MaterialWithRecipes
}

export const RawMaterialsClassification: React.FC<RawMaterialsClassificationProps> = () => {
  const {t} = useTranslation()

  const {data: rawMaterialsClassificationMetadata} = useMaterialsClassificationMetadata(
    MaterialType.RawMaterials
  )

  const {disabled, setValue, getValues, clearErrors} = useMaterialForm()

  if (!rawMaterialsClassificationMetadata) return <Skeleton variant="rectangular" height={66} />

  return (
    <Stack
      spacing={2}
      {...dataTestId('clinker_classification_form')}
      sx={{maxWidth: {xs: '100%', sm: (theme) => theme.spacing(49)}}}
    >
      <Stack direction="row" alignItems="center">
        <ClassificationCheckBox
          materialType={MaterialType.RawMaterials}
          inputName={
            RawMaterialClassificationInputNamesInternal.IsThermallyProcessedClinkerSubstitute
          }
          customValues={{trueValue: 'Yes', falseValue: 'No'}}
          metadata={rawMaterialsClassificationMetadata}
          isDisabled={disabled}
        />
        <Tooltip
          title={
            <Stack spacing={1}>
              <Typography variant="body1">
                {t(
                  'materialsSettings.classificationInputs.RawMaterials.isThermallyProcessedClinkerSubstituteTooltip'
                )}
              </Typography>
              <Typography variant="body2">
                {t(
                  'materialsSettings.classificationInputs.RawMaterials.isThermallyProcessedClinkerSubstituteTooltipDescription'
                )}
              </Typography>
            </Stack>
          }
          placement="right"
        >
          <InfoOutlined fontSize="small" />
        </Tooltip>
      </Stack>
      <ClassificationSegmentedButton
        materialType={MaterialType.RawMaterials}
        inputName={RawMaterialClassificationInputNamesInternal.RawMaterialType}
        metadata={rawMaterialsClassificationMetadata}
        isDisabled={disabled}
        onChangeCb={() => {
          setValue('classification', {
            ...getValues('classification'),
            [RawMaterialClassificationInputNamesInternal.RawMaterialCategory]: null,
            [RawMaterialClassificationInputNamesInternal.RawMaterialSubCategory]: null,
            [CommonMaterialClassificationInputNames.FormSize]: null,
            [CommonMaterialClassificationInputNames.ProcessingDegree]: null
          })
          clearErrors([
            `classification.${RawMaterialClassificationInputNamesInternal.RawMaterialCategory}`,
            `classification.${RawMaterialClassificationInputNamesInternal.RawMaterialSubCategory}`,
            `classification.${CommonMaterialClassificationInputNames.FormSize}`,
            `classification.${CommonMaterialClassificationInputNames.ProcessingDegree}`
          ])
        }}
      />
      <ClassificationAutocomplete
        materialType={MaterialType.RawMaterials}
        inputName={RawMaterialClassificationInputNamesInternal.RawMaterialCategory}
        metadata={rawMaterialsClassificationMetadata}
        onChangeCb={() => {
          setValue('classification', {
            ...getValues('classification'),
            [RawMaterialClassificationInputNamesInternal.RawMaterialSubCategory]: null,
            [CommonMaterialClassificationInputNames.FormSize]: null,
            [CommonMaterialClassificationInputNames.ProcessingDegree]: null
          })
          clearErrors([
            `classification.${RawMaterialClassificationInputNamesInternal.RawMaterialSubCategory}`,
            `classification.${CommonMaterialClassificationInputNames.FormSize}`,
            `classification.${CommonMaterialClassificationInputNames.ProcessingDegree}`
          ])
        }}
        isDisabled={disabled}
      />
      <ClassificationAutocomplete
        materialType={MaterialType.RawMaterials}
        inputName={RawMaterialClassificationInputNamesInternal.RawMaterialSubCategory}
        metadata={rawMaterialsClassificationMetadata}
        isDisabled={disabled}
      />
      <ClassificationAutocomplete
        materialType={MaterialType.RawMaterials}
        inputName={CommonMaterialClassificationInputNames.FormSize}
        metadata={rawMaterialsClassificationMetadata}
        isDisabled={disabled}
      />
      <ClassificationAutocomplete
        materialType={MaterialType.RawMaterials}
        inputName={CommonMaterialClassificationInputNames.ProcessingDegree}
        metadata={rawMaterialsClassificationMetadata}
        isDisabled={disabled}
      />
    </Stack>
  )
}
