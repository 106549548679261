import {Status, UnitType} from '../enums'

export type Group = {
  id: string
  name: string
  status: Status
  groups?: Group[]
}

export type CategoriesTreeResponse = {
  businessAreas: Group[]
}

export interface Value {
  date: string
  version: number
  value?: string
  comments?: number
  kpiValueId: string
}

export type CommentsRequest = {
  kpiValueId: string
  kpiName: string
  unitName: string
}

export type ResourceDetailsResponse = {
  specificKpiId: string
  name: string
  unit: ResourceUnit
  kpiGroup: KpiGroup
  values: Value[]
}[]

export type ResourceDetails = {
  specificKpiId: string
  name: string
  unit: ResourceUnit
  kpiGroup: KpiGroup
  value: string
  version: number
  date: string
}[]

export type EditResourceDetails = Record<string, ResourceDetails>

export type ResourceGroups = Record<string, ResourceDetailsResponse>

export interface ResourceUnit {
  id: UnitType
  unitName: string
  abbreviation: string
}

export interface KpiGroup {
  id: string
  name: string
  businessAreaTypeId: number
  businessAreaName: string
}
