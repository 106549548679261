import {useMemo} from 'react'
import {useFormContext, UseFormReturn} from 'react-hook-form'
import {useIsMutating} from 'react-query'

import {MaterialFormDefaultValues} from '../../MaterialDetailsCard/hooks'

export const useMaterialForm = () => {
  const formContext: UseFormReturn<MaterialFormDefaultValues> = useFormContext()

  const runningMutations = useIsMutating(['editMaterial'])
  const disabled = Boolean(runningMutations)

  return useMemo(() => ({...formContext, disabled}), [disabled, formContext])
}
