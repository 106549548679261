import {MaterialType} from '@hconnect/common/types'
import {Skeleton, Box} from '@mui/material'

import {useMaterialsClassificationMetadata} from '../../../hooks/queries'

import {ClassificationAutocomplete} from './ClassificationAutocomplete'

import {AdditivesForClinkerClassificationInputNames} from '@settings/modules/common/enums'

interface AdditivesForClinkerClassificationProps {
  isDisabled: boolean
}

export const AdditivesForClinkerClassification: React.FC<
  AdditivesForClinkerClassificationProps
> = ({isDisabled}) => {
  const {data: additivesForClinkerClassificationMetadata} = useMaterialsClassificationMetadata(
    MaterialType.AdditivesForClinker
  )

  return additivesForClinkerClassificationMetadata ? (
    <Box sx={{width: ({spacing}) => spacing(49)}}>
      <ClassificationAutocomplete
        materialType={MaterialType.AdditivesForClinker}
        inputName={AdditivesForClinkerClassificationInputNames.Category}
        metadata={additivesForClinkerClassificationMetadata}
        isDisabled={isDisabled}
      />
    </Box>
  ) : (
    <Skeleton variant="rectangular" sx={{height: ({spacing}) => spacing(10)}} />
  )
}
